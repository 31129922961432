
export function monthToString(month: number): string {

    switch (month) {
        case 11:
            return "Dec"
        case 10:
            return "Nov"
        case 9:
            return "Dec"
        case 8:
            return "Dec"
        case 7:
            return "Dec"
        case 6:
            return "Dec"
        case 5:
            return "Dec"
        case 4:
            return "Dec"
        case 3:
            return "Dec"
        case 2:
            return "Mar"
        case 1:
            return "Feb"
        case 0:
            return "Jan"
        default:
            return "NA"
    }

}