import { IonButton, IonButtons, IonContent, IonHeader, IonInput, IonItem, IonLabel, IonList, IonPage, IonRadio, IonRadioGroup, IonSelect, IonSelectOption, IonText, IonTextarea, IonTitle, IonToolbar } from "@ionic/react"
import { useEffect, useState } from "react";
import axios from 'axios'
import './NewMessagePage.css'
import Loading from "./Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Data from "../interfaces/StrapiData";
import { useHistory } from "react-router";


interface Group {
    name: string
}

const NewMessagePage: React.FC = () => {

    // const [title, setTitle] = useState("")
    const [message, setMessage] = useState("")
    const [groups, setGroups] = useState<Group[]>([])
    const [sendTime, setSendTime] = useState<string>("now")

    const { getAccessTokenSilently } = useAuth0()
    const history = useHistory()

    useEffect(() => {
        const strapiToken = localStorage.getItem("jwt")
        console.log(strapiToken)
        axios.get(`${process.env.REACT_APP_API_URL!}/groups`, {
            headers: {
                Authorization: `Bearer ${strapiToken}`
            }
        }).then((res) => {
            if (res.data != null) {
                const data: Data<Group>[] = res.data.data

                var groups: Group[] = []

                data.forEach(group => {
                    groups.push(group.attributes)
                });

                setGroups(groups)
            }
        }).catch((err) => {
            console.error(err)
            alert("Failed to retrieved group of recipients")
        })
    }, [])

    function sendMessage(message: string) {

        getAccessTokenSilently().then((token) => {
            console.log(token)
            axios.post(`${process.env.REACT_APP_MESSAGING_URL!}/campaigns`, { body: message, to: ["all"], title: "My Campaign" }, { headers: { Authorization: `Bearer ${token}` } }).then((res) => {
                alert("Succesfully sent message!")
                history.push("/")
            }).catch((err) => {
                console.error(err)
                alert("Failed to send message!")
            })
        }).catch((err) => {
            console.error(err)
            alert("unable to authroized request")
        })

    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton routerDirection="back">Back</IonButton>
                    </IonButtons>
                    <IonTitle>New Campaign</IonTitle>

                    <IonButtons slot="end">
                        <IonButton onClick={() => { sendMessage(message) }}>Next</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {/* <IonItem>
                    <IonLabel position="stacked">Title</IonLabel>
                    <IonInput defaultValue={title}></IonInput>
                </IonItem> */}
                <IonList lines="inset" inset>
                    <IonItem>
                        <IonLabel position="stacked">
                            <h1>To</h1>
                        </IonLabel>
                        <IonSelect>
                            {groups.map((group, index) => {
                                return (
                                    <IonSelectOption>{group.name}</IonSelectOption>
                                )
                            })}
                        </IonSelect>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked"><h1>Message</h1></IonLabel>
                        <IonTextarea defaultValue={message} onIonChange={(e) => { setMessage(e.detail.value!) }}></IonTextarea>

                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked"><h1>Send Message</h1></IonLabel>
                        <IonRadioGroup value={sendTime} onIonChange={(e) => { setSendTime(e.detail.value) }}>
                            <IonItem>
                                <IonLabel>Now</IonLabel>
                                <IonRadio slot="end" value="now"></IonRadio>
                            </IonItem>
                            <IonItem>
                                <IonLabel>Later</IonLabel>
                                <IonRadio slot="end" value="later"></IonRadio>
                            </IonItem>
                        </IonRadioGroup>
                    </IonItem>
                </IonList>
                <IonItem lines="none">
                    <IonButton size="default" onClick={() => sendMessage(message)}>Send Message</IonButton>

                </IonItem>
            </IonContent>
        </IonPage>
    )
}

export default withAuthenticationRequired(NewMessagePage, {
    onRedirecting: () => <Loading />
});