import { IonFab, IonFabButton, IonIcon } from "@ionic/react"
import { add } from "ionicons/icons"

const NewMessageButton: React.FC = () => {
    return (
        <IonFab slot="fixed" vertical="bottom" horizontal="end">
            <IonFabButton routerLink="/messages/new">
                <IonIcon icon={add}></IonIcon>
            </IonFabButton>
        </IonFab>

    )
}

export default NewMessageButton;