import { Link, Redirect, Route } from 'react-router-dom';
import { useHistory } from 'react-router';

import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { ellipse, square, textOutline, triangle } from 'ionicons/icons';
import Tab1 from './pages/Tab1';
import Tab2 from './pages/Tab2';
import Tab3 from './pages/Tab3';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import MessagesPage from './pages/MessagesPage';
import NewMessagePage from './pages/NewMessagePage';
import SelectContactsPage from './pages/SelectContactsPage';
import LoginRedirect from './components/LoginRedirect';
import { useEffect } from 'react';

setupIonicReact();

const App: React.FC = () => {
  const history = useHistory()

  useEffect(() => {
    const strapiToken = localStorage.getItem("jwt")

    if (strapiToken == null) {
      window.location.href = `${process.env.REACT_APP_API_URL}/connect/auth0`
      console.log(window.location.href)
      return
    }
  }, [])

  return (
    <IonApp>
      <IonReactRouter>
        <IonTabs>
          <IonRouterOutlet>
            <Route exact path="/messages">
              <MessagesPage />
            </Route>
            <Route exact path="/">
              <Redirect to="/messages" />
            </Route>
            <Route exact path="/messages/new">
              <NewMessagePage />
            </Route>
            <Route exact path="/select-contacts">
              <SelectContactsPage />
            </Route>
            <Route exact path="/redirect">
              <Link to={{ pathname: `${process.env.REACT_APP_API_URL}/connect/auth0` }} target="_blank" />
            </Route>
            <Route exact path="/connect/:providerName/redirect" component={LoginRedirect} />
          </IonRouterOutlet>
          <IonTabBar slot="bottom">

            <IonTabButton tab='messages' href="/messages">
              <IonIcon icon={textOutline} />
              <IonLabel>Messages</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
    </IonApp>
  )
}
export default App;
