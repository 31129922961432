import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonItem, IonLabel, IonList, IonNote, IonPage, IonRow, IonTitle, IonToolbar } from "@ionic/react"
import axios from "axios";
import { useEffect, useState } from "react";
import NewMessageButton from "../components/NewMessageButton";
import { monthToString } from "../helpers/utilities";
import Data from "../interfaces/StrapiData";
import Loading from "./Loading";

interface Campaign {
    success_rate: number
    title: string
    body: string
    createdAt: string
}

const MessagesPage: React.FC = () => {
    const { loginWithRedirect, logout, isAuthenticated, } = useAuth0()
    const [campaigns, setCampaigns] = useState<Campaign[]>([])

    function test() {

    }

    useEffect(() => {
        const strapiToken = localStorage.getItem("jwt")
        console.log(strapiToken)
        axios.get(`${process.env.REACT_APP_API_URL!}/messages`, {
            headers: {
                Authorization: `Bearer ${strapiToken}`
            }
        }).then((res) => {
            if (res.data != null) {
                const data: Data<Campaign>[] = res.data.data

                var campaigns: Campaign[] = []

                data.forEach(campaign => {
                    campaigns.push(campaign.attributes)
                });

                setCampaigns(campaigns)
            }
        }).catch((err) => {
            console.error(err)
            alert("Failed to retrieved campaign info")
        })
    }, [])

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Campaigns</IonTitle>
                </IonToolbar>

            </IonHeader>
            <IonContent>

                {/* <IonButton onClick={() => { logout() }}>Logout</IonButton>
                <IonButton href={`${process.env.REACT_APP_API_URL}/connect/auth0`}>Login</IonButton> */}
                <IonList inset>
                    {campaigns.map((campaign, index) => {
                        return (
                            <IonItem key={index}>
                                <IonGrid>
                                    <IonRow >
                                        <IonCol>
                                            <IonLabel>
                                                <h1>{campaign.title}</h1>
                                                <p>{campaign.body}</p>
                                            </IonLabel>
                                        </IonCol>
                                        <IonCol >
                                            <IonLabel><p slot="end" className="ion-text-end">{`${monthToString(new Date(campaign.createdAt).getMonth())} ${new Date(campaign.createdAt).getDate()} `}</p></IonLabel>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </IonItem>
                        )
                    })}
                </IonList>
                <NewMessageButton />
            </IonContent>
        </IonPage>
    )
}

export default withAuthenticationRequired(MessagesPage, {
    onRedirecting: () => <Loading />
});