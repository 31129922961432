
import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
// import { useHistory, useLocation, useParams } from "react-router-dom";

const backendUrl = "http://localhost:1337";

const LoginRedirect = () => {
    const [text, setText] = useState('Loading...');
    const location = useLocation();
    const params = useParams();
    const history = useHistory();
    const { loginWithRedirect, logout, isAuthenticated, } = useAuth0()

    useEffect(() => {
        // Successfully logged with the provider
        // Now logging with strapi by using the access_token (given by the provider) in props.location.search
        fetch(`${backendUrl}/api/auth/auth0/callback${location.search}`)
            .then(res => {
                if (res.status !== 200) {
                    throw new Error(`Couldn't login to Strapi. Status: ${res.status}`);
                }
                return res;
            })
            .then(res => res.json())
            .then(res => {
                // Successfully logged with Strapi
                // Now saving the jwt to use it for future authenticated requests to Strapi
                localStorage.setItem('jwt', res.jwt);
                localStorage.setItem('username', res.user.username);
                setText('You have been successfully logged in. You will be redirected in a few seconds...');
                setTimeout(() => history.push('/'), 3000); // Redirect to homepage after 3 sec
            })
            .then((res) => {
                console.log(isAuthenticated)
                if (!isAuthenticated) {
                    loginWithRedirect()
                }

            })
            .catch(err => {
                console.log(err);
                setText('An error occurred, please see the developer console.')
            });
    }, [history, location.search]);

    return <p>{text}</p>
};

export default LoginRedirect;