import { IonButton, IonButtons, IonCheckbox, IonContent, IonHeader, IonItem, IonLabel, IonList, IonPage, IonTitle, IonToolbar } from "@ionic/react"

const SelectContactsPage: React.FC = () => {
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton routerDirection="back">Back</IonButton>
                    </IonButtons>
                    <IonTitle>Select Recipients</IonTitle>
                    <IonButtons slot="end">
                        <IonButton>Send</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    <IonItem>
                        <IonCheckbox slot="start"></IonCheckbox>
                        <IonLabel>All</IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonCheckbox slot="start"></IonCheckbox>
                        <IonLabel>1235677890</IonLabel>
                    </IonItem>
                </IonList>
            </IonContent>
        </IonPage>
    )
}

export default SelectContactsPage;