import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from "@auth0/auth0-react";

const container = document.getElementById('root');
const root = createRoot(container!);

// console.log(window.location.origin)
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain='dev-jbzikh2vmwubdaod.us.auth0.com'
      clientId='X8f1UMaTWKVQG0BOZs5HtJ43tpcBvaKV'
      redirectUri={window.location.origin}
      audience="https://hom-sms-dev"
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
